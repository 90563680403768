// Overwriting default styling for Bootstrap Alerts
.alert.dev-alert {
  // Needs to be present, else alerts are not visible
  opacity: 1;

  background-color: var(--dev-alert-bg);
  border: none;
  border-radius: 0;

  margin: 0;

  &.alert--lightgreen {
    background-color: lightseagreen;

    p {
      color: white;
      font-weight: 400;
    }
  }

  button.close {
    top: -2px;

    span {
      color: white;
    }

    &:hover {
      color: var(--dev-alert-hover);
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  p {
    color: var(--dev-alert-font);
    margin: 0;

    svg {
      font-weight: 900;
      text-align: center;
      line-height: 1;
      position: relative;
      top: -2px;
    }
  }
}
