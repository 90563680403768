// Overwriting single styling property for when a user is on the homepage
@media (min-width: 1200px) {
  nav.home-nav {
    background-color: transparent;
  }
}

// Overwriting styling for the default navbar
.navbar {
  background-color: var(--nav-bg);
  padding: 0.25rem 0.75rem !important;

  z-index: 10;

  // Removing default behaviour to add a box on focus
  a:focus,
  span:focus,
  img:focus {
    outline: none;
    text-decoration: none;
  }

  // navbar-brand = logo & name
  > .navbar-brand {
    padding: 0.625rem 0;

    &:focus {
      outline: none;
    }

    &:hover,
    a:hover {
      text-decoration: none;
    }

    img {
      width: 32px;
      height: 32px;
    }

    span {
      color: var(--nav-active);
      padding: 0 0.625rem;

      font-size: 1rem;
      font-weight: 600;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

      @media (max-width: 576px) {
        font-weight: 500;
      }
    }
  }

  #basic-navbar-nav {
    // Styling the dropdown arrow icons -- Removing default dropdown arrow icon
    .dropdown-toggle::after {
      display: none;
    }

    .account-dropdown {
      a#basic-nav-dropdown {
        padding: 0.625rem 0 !important;
      }
    }

    .navbar-nav {
      margin: 0 1px 0 0;

      .dropdown {
        // Maintain highlight NavDropown has when dropdown menu is opened
        &.show .dropdown-toggle.nav-link {
          color: var(--nav-hover) !important;
        }

        // Styling the dropdown arrow icons -- Removing default dropdown arrow icon
        .dropdown-toggle.nav-link {
          svg {
            margin-left: 0.35rem;
          }

          &.active {
            color: var(--nav-active) !important;
            border-bottom: 1px solid var(--nav-active) !important;

            &:hover {
              color: var(--nav-active);
            }
          }
        }
      }
    }

    // Following code is styles the right-side of navbar
    .nav-link {
      color: var(--nav-default);
      padding: 0.625rem 0.5rem;

      font-size: 0.875rem;
      font-weight: 500;

      transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

      &:hover {
        color: var(--nav-hover);
      }
    }

    // Adding underline to "active" pages
    .nav-link--active {
      color: var(--nav-active);
      border-bottom: 1px solid var(--nav-active);

      &:hover {
        color: var(--nav-active);
      }

      a#basic-nav-dropdown {
        color: var(--nav-active);
      }
    }

    // Overwriting style for the navbar's dropdown
    .dropdown-menu {
      box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11),
        0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
      border-radius: 0.375rem;

      a.dropdown-item {
        color: var(--nav-dropdown-font);
        font-size: 0.9375rem;

        padding: 0.3rem 1.25rem;

        // Overwriting default styling of an "active" dropdown element
        &.active {
          color: var(--nav-dropdown-font);
          background-color: #fff;
        }

        // Overwriting default style of "active" dropdown elements with specific class, as those that use `href` have a weird behaviour otherwise
        &.active.--active-item,
        &.active.--active-item:hover {
          color: var(--nav-active);
          background-color: var(--nav-dropdown-bg-active);
        }

        &:hover {
          background-color: var(--nav-dropdown-bg-hover);
        }
      }
    }

    // Formatting for Login at the end of the Nav
    a.btn-login {
      color: var(--nav-active);
      font-size: 0.75rem;

      border: 1px solid;
      border-radius: 0.35rem;

      padding: 0.35rem 1rem;
      margin: 6px 0 4px 0.45rem;

      transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

      &:hover {
        background-color: var(--nav-active);
        color: var(--nav-bg);
        text-decoration: none;

        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05),
          0 4px 10px rgba(255, 255, 255, 0.25);
      }

      &:active {
        box-shadow: none;
      }
    }
  }
}
