// File used for global styles

// Importing fonts for use
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');

// Global variables used for styling
:root {
  // Styling for the navigation
  --nav-bg: #0c0d0e;
  --nav-active: #fdfdfd;
  --nav-hover: rgba(255, 255, 255, 0.75);
  --nav-default: rgba(255, 255, 255, 0.5);

  --nav-dropdown-font: #212529;
  --nav-dropdown-bg-hover: #e2e2e2;
  --nav-dropdown-mobile-hover: #1c1c1c;
  --nav-dropdown-bg-active: #c3c7cc;
  --nav-dropdown-mobile-active: #343434;

  // Styling for the footer
  --footer-bg: #0c0d0e;
  --footer-default: rgba(255, 255, 255, 0.5);
  --footer-hover: rgba(255, 255, 255, 0.75);
  --footer-title: #fdfdfd;

  // Styling for the "Under Development" alerts
  --dev-alert-bg: #5A6169;
  --dev-alert-font: rgba(255, 255, 255, 0.75);
  --dev-alert-hover: #8c949d;

  // Styling for virtual observatory
  --vo-gallery-bg: #202020;
  --vo-sidebar-bg: #171717;
  --vo-filters-bg: #272727;

  // Form warning
  --form-warning: #e93535;

  // --sidebar-default: #565656;
  --sidebar-default: rgba(255, 255, 255, 0.5);
  --sidebar-hover: rgb(226, 226, 226);
  --sidebar-options: #0c0d0e;

  --box-shadow: rgba(136, 136, 136, 0.333);

  --header-title-font: #212529;
  --paragraph-font: #5A6169;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafc;
}

// Default headers for most pages
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-title-font);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3.052rem;
}

p {
  color: var(--paragraph-font);
  font-weight: normal;
  text-align: justify;
  font-size: .95rem
}

b {
  font-weight: 500;
}

// navigation
.navbar #basic-navbar-nav .dropdown-menu a.dropdown-item {
  padding: 0.35rem 1.05rem;
}

// Styling for modals as rsuite overwrites it:
.modal-open .modal {
  opacity: 1;
  pointer-events: all !important;

  button.close {
    margin: 3px 10px 3px 5px;
    padding: 0;

    span {
      font-size: 25px;
    }

    &:focus {
      outline: none;
    }
  }
}

// Preferred styling for buttons on site, overrides bootstrap styling
.btn {
  border-radius: 0.5em;
}

// Prevents grey when active but not hovering over button
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled):active {
  background-color: #000;
  box-shadow: 3px 5px 5px -1px rgba(0, 0, 0, 0.2);
}

// Prevents default focus on buttons
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

/* Styling for btn-outline-dark */
.btn.btn-outline-dark {
  &:hover {
    background-color: #000;
    box-shadow: 3px 5px 5px -1px rgba(0, 0, 0, 0.2);
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  &:disabled,
  &:disabled:hover {
    background-color: transparent !important;
    box-shadow: none;
  }
}

/* Styling for btn-dark */
.btn.btn-dark {
  background-color: #0c0d0e;

  &:hover {
    background-color: #000;
    box-shadow: 3px 5px 5px -1px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled:hover {
    background-color: #0c0d0e;
    box-shadow: none;
  }
}

/* Styling for btn-light */
.btn.btn-light {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    box-shadow: 3px 5px 5px -1px rgba(0, 0, 0, 0.2);
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  &:focus {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: #e2e6ea;
      border-color: #dae0e5;
      box-shadow: 3px 5px 5px -1px rgba(0, 0, 0, 0.2);

      &:active {
        box-shadow: none;
      }
    }
  }
}

// override rsuite override of interactivity on alerts, modals
.fade {
  pointer-events: all !important;
}

/* Styling for rsuite elements */
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--header-title-font);

  // Prevents select count from appearing
  .rs-picker-value-count {
    background-color: rgba(0, 0, 0, 0);
    width: 0px;
    margin: 0px;
    padding: 0px;
  }
}

// Font-size for dropdown menu options
.rs-checkbox label,
.rs-picker-select-menu-item,
.rs-check-item .rs-checkbox-checker>label,
.rs-picker-check-menu-items .rs-picker-check-menu-group-title,
.rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker>label {
  font-size: 14px;
}

// Selectpicker
.rs-picker-menu.fade.rs-picker-check-menu {
  z-index: 1050;
}

.rs-checkbox.rs-check-item {
  margin: 0 10px;
}

.rs-picker-select-menu-item {
  padding-left: 15px;
}

// Checkboxes
.rs-checkbox.rs-checkbox-inline.rs-checkbox-indeterminate .rs-checkbox-checker .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
  background-color: #494949;
  border-color: #494949;
}

// Prevents selected items from having bold weight
.rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker>label,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active.rs-picker-select-menu-item-focus {
  font-weight: normal;
}

// Prevents shadow from appearing on dropdown and ensures it is opaque
.rs-picker-menu.fade.in.rs-picker-check-menu,
.rs-picker-menu.fade.in.rs-picker-select-menu {
  opacity: 1;

  box-shadow:
    0 0 1px rgba(0, 0, 0, 0.06),
    0 1px 1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow:
    0 0 1px rgba(0, 0, 0, 0.06),
    0 1px 1px rgba(0, 0, 0, 0.12);
}

// Styling for Bootstrap tooltips
.tooltip.show {
  opacity: 1;
}

// Used to prevent highlighting text when we don't want that to be possible
.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}