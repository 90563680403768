/*
 * File created to separate content specific to mobile/tablet formatting
 * and to reduce the amount of content in Navbar.css
*/

.navbar {
  // Styling the toggle icon border for mobile/tablet devices
  .navbar-toggler {
    font-size: 1rem;
    border-radius: 50%;

    color: var(--nav-hover);

    padding: 0.5rem;

    &:hover,
    &.collapsed:hover {
      border-color: var(--nav-hover);
    }

    &:focus {
      outline: none;
    }

    // Styling the toggle icon for mobile/tablet devices
    span.navbar-toggler-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

// Styling the dropdown for mobile devices
@media (max-width: 1199) {
  .navbar #basic-navbar-nav {
    // Overwriting default, mobile styling of toggle nav
    .dropdown-menu {
      background-color: var(--nav-bg);
      padding: 0;

      .dropdown-divider {
        border-top: 0.01rem solid var(--nav-default);
      }

      // Overwrite default styling of an dropdown menu elements
      &.dropdown-menu-right a.dropdown-item {
        color: var(--nav-default);
        font-size: 0.8rem;

        &.active {
          background-color: var(--nav-dropdown);
        }

        &:hover,
        &.active:hover {
          color: var(--nav-hover);
          background-color: var(--nav-dropdown-mobile-hover);
        }

        // Overwrite default styling of an "active" dropdown element
        &.active.--active-item,
        &.active.--active-item:hover {
          color: var(--nav-hover);
          background-color: var(--nav-dropdown-mobile-active);
        }
      }
    }

    // Removing underline associated with "active" page
    .nav-link.nav-link--active,
    .nav-item.nav-link--active {
      border-bottom: none;
    }

    // Styling for "Login" as it's outside the <nav> tag
    a.btn-login {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--nav-default);

      border: none;

      line-height: 2.5rem;
      margin: 0;
      padding: 0 0.625rem;

      &:hover {
        color: var(--nav-hover);
        background-color: transparent;
        text-decoration: none;
        box-shadow: none;
      }
    }
  }
}

// Styling the close button used to toggle the visibility of the nav sidebar to false
svg.sidebar-icon.close-icon {
  color: var(--nav-default);

  position: absolute;
  top: 25px;
  right: 25px;

  &:hover {
    cursor: pointer;
    color: var(--nav-hover);
  }

  &:focus {
    outline: none;
  }
}
