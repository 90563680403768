// Styling for Intro-JS
.introjs-tooltipReferenceLayer {
  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  }

  visibility: visible;

  .skip-tutorial {

    #tutorial-checkbox:hover,
    label:hover {
      cursor: pointer !important;
    }

    #tutorial-checkbox {
      margin: 0 10px 0 0;
    }
  }

  .introjs-tooltip {
    min-width: 400px;
    max-width: 600px;
    width: 600px;

    .introjs-floating {
      transform: translate(-50%, -50%);
      margin: 0 !important;
    }
  }

  .introjs-tooltip-header {
    padding: 5px 10px 0;
  }

  .introjs-tooltiptext {
    padding: 0px 20px 0px 20px;
  }

  .introjs-skipbutton {
    padding: 5px 2px;

    &:hover {
      color: white;
    }
  }
}