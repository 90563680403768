div.footer-container {
  // The current way images are set-up cause overflow in x-direction on small screens
  overflow-x: hidden;
  background-color: var(--footer-bg);
  transition: transform 350ms cubic-bezier(0.5, 0.63, 0.18, 0.98);
  padding: 5rem 1.5rem 1.5rem;
  line-height: 1.3;

  ul {
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--footer-default);
  }

  a:focus,
  a:hover {
    outline: none;
    text-decoration: none;
  }

  .footer-main {
    display: flex;
    flex-wrap: wrap;

    padding: 0 0 3rem;

    .link-container {
      display: flex;
      flex-wrap: wrap;

      margin: 0 auto;

      @media (max-width: 576px) {
        padding: 0 0 0 3.5rem;
      }

      @media (min-width: 1270px) {
        margin: 0 0 0 auto;
      }
    }

    // Styling the footer nav links
    .links {
      padding: 0 1.75rem;

      .footer-title {
        font-size: 1rem;
        font-weight: 500;
        color: var(--footer-title);

        margin: 0.725rem 0 0.5rem;
      }

      li {
        font-size: 14px;
        text-decoration: none;
        list-style-type: none;

        margin-bottom: 0.275rem;

        a:hover {
          color: var(--footer-hover);
        }
      }
    }

    // Styling the logos found on the footer
    .images {
      text-align: center;
      display: block;

      width: 90%;
      margin: 0 auto;

      img {
        max-height: 100%;
        margin: 0.75rem 1.25rem;
      }

      @media (max-width: 1270px) {
        padding: 1rem 0;
        width: 90%;
      }

      @media (min-width: 1270px) {
        width: 50%;
        margin: 0 auto 0 50px;
      }
    }
  }

  // Styling the Copyright and Version info at the bottom of the footer
  section.copyright-wrapper {
    p {
      color: var(--footer-default);
      text-align: center;
      font-size: 85%;

      a:hover {
        color: var(--footer-hover);
      }
    }
  }
}
