div.dropdown-container {
  // top-level styles
  font-size: 14px;
  overflow-x: hidden;
  position: relative;
  padding: 1.75rem 0.5rem 1.75rem 1.75rem;
  flex: 1 0 auto;

  span:focus,
  input:focus {
    outline: none;
    box-shadow: none !important;
    text-decoration: none;
  }

  // Bootstrap styling puts it at -15px
  .row {
    margin: 0;
  }

  .form-control-sm {
    border-radius: 0.3rem;
  }

  /* Styling for every table on page */
  table thead tr th {
    font-weight: 500;
  }

  .criteria-table {
    width: 950px;
  }

  // search button
  .search-btn-group {
    margin-top: 10px;
    width: 175px;
  }

  .search-button-searching {
    width: 100%;
    pointer-events: none;
  }

  .search-button-not-searching {
    width: 175px;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .cancel-button {
    padding-left: 8px;
    padding-right: 8px;
  }

  /* When the distance is "advanced", the cog background turns to a different colour */
  button.cog-advanced-distance {
    background-color: #28a745;
    border-color: #28a745;

    &:active,
    &:focus,
    &:focus:active {
      background-color: #28a745;
      border-color: #28a745;
    }

    &:hover,
    &:hover:active,
    &:focus:hover {
      background-color: #218838;
      border-color: #1e7e34;
    }

    svg {
      color: #fdfdfd;
    }
  }

  /* Styling of the criteria input panel */
  #input-panel {
    .input-panel--left {
      margin-right: 20px;
    }

    .reset-critieria-row,
    .remove-critieria-row {
      padding-left: 2px;
      padding-right: 2px;
    }

    // Styling for labels in the input panel ////////
    label.conjunction-label-text p {
      color: var(--header-title-font);
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    .input-fields--top {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      #input-fields--inputs {

        #date-range-input,
        #distance-input,
        #conjunction-type-input {
          margin: 0 10px 0 0;
        }

        #distance-input input {
          width: 75px;
        }

        input,
        select,
        button {
          margin: 5px 0 0;
        }
      }

      #input-fields--buttons {
        margin: 26px 0 0;
      }

      .disabled-distances-checkbox {
        .rs-checkbox-wrapper {
          top: 13px;
          left: 5px;
        }

        .rs-checkbox-checker {
          padding-top: 13px;
          padding-left: 25px;

          label {
            font-size: 11px;
          }
        }
      }

      .toggle-div {
        .rs-checkbox-wrapper {
          top: 30px;
        }

        .rs-checkbox-checker {
          padding-top: 30px;
          padding-left: 30px;

          label {
            font-size: 11px;
          }
        }
      }
    }

    // Styling of the criteria table ////////
    #input-fields--bottom {
      padding: 5px 0 0;

      .criteria-table {
        thead>tr {
          outline: 1px #dee2e6 solid;

          th {
            color: var(--header-title-font);
            font-size: 13px;
            background-color: #f3f3f3;
            border: none !important;

            padding: 7px 0 7px 5px;
          }

          .name-col {
            width: 70px !important;
          }

          .programs-col,
          .platforms-col,
          .instrument-col,
          .hemisphere-col {
            width: 120px;
          }

          .button-col {
            width: 20px;
          }

          .metadata-col {
            width: 125px;
          }
        }

        tbody>tr {
          td {
            vertical-align: middle !important;
            font-size: 12.5px;

            border: none; // Removes tables row lines
            padding: 7.5px 3px 0;

            .name-col {
              width: 60px !important;
            }

            .programs-col,
            .platforms-col,
            .instrument-col,
            .hemisphere-col {
              width: 75px;
            }

            .button-col {
              width: 20px;
            }
          }
        }
      }
    }

    // Styling for criteria sidebar (ie. examples, tools)
    #conjunction-right-sidebar {
      #load-example-label {
        padding: 0 8px 0 0;
      }

      #swarm-tool-label {
        padding: 0 11px 0 0;
      }

      #deep-links-label {
        padding: 0 33px 0 0;
      }

      #tool-label {
        padding: 0 68px 0 0;
      }

      .swarm-tool-icons {
        margin: -4px 0 0 4px;
      }

      .example-btn button {
        line-height: 0rem;
        width: 25px;
        height: 25px;
      }

      .tutorial-btn {
        line-height: 0;
        height: 25px;
      }

      .progress-query-container {
        padding: 0;
        margin-top: 10px;
        margin-left: 15px;
        width: 230px;

        p {
          color: var(--nav-dropdown-font);
          font-size: 14px !important;
          margin: 0 0 5px;
        }

        .progress {
          width: 97%;
        }
      }

      span#result-timing {
        min-width: 300px;
        margin: 10px 0 0;
      }
    }
  }

  .tools-dropdown-item {
    display: inline-flex;
    align-items: center;
  }

  // Styling for results table and all other relevant result info
  .results-container {
    padding: 0 10px 0 0;

    .row>.col-md-6 {
      padding: 0;
    }

    div.dataTables_wrapper {
      .pagination {

        .page-item a.page-link:focus,
        .page-item a.page-link:active {
          box-shadow: none;
          text-decoration: none;
        }
      }

      div.dataTables_info {
        padding: 0;
      }
    }

    // table
    table.conjunction-table {
      width: 100% !important;

      thead,
      tbody {
        outline: 1px #dee2e6 solid
      }

      td {
        a {
          font-size: 12px;
          line-height: 0.95rem;

          // Needed to prevent Open button text from staying black
          &:hover:focus,
          &:active:focus,
          &:hover:active:focus {
            color: white;
          }

          &:focus {
            color: black;
          }
        }
      }
    }
  }
}

// modal styles -- must be out of the container
// ---------------------------------------------
// Styling for Metadata filter modal
.modal--metadata-filter .modal-body {
  .logical-operator-selector {
    width: 75px;
    margin: 0 0 10px 0;
  }

  label {
    color: var(--paragraph-font);
  }

  .table-compact.dropdown-metadata-modal-table {
    font-size: 14px;

    // row styling
    tr td {
      vertical-align: middle;

      // styling of selectpickers in modals
      &.metadata-section--multi {
        width: 240px;
      }
    }

    // header styling
    thead {
      border: 1px solid #dae0e5;

      tr th {
        font-weight: 500;
      }
    }
  }
}

// Styling for advanced distance modal
.modal--advanced-distances .modal-body .row,
.modal--advanced-distances .modal-body .row input {
  font-size: 14px;
}

// Styling for raw results and about query -- copy icon
.modal--raw-results .modal-body,
.modal--about-query .modal-body {
  h4 svg {
    margin: -4px 0 0 8px;

    &:hover {
      cursor: pointer;
      fill: var(--dev-alert-bg);
    }
  }
}

// Styling query time modal
.about-query-time--modal .modal-content {
  .modal-body {
    font-size: 14px;

    p {
      font-size: 14px;
      color: black;
    }
  }
}

// Styling for custom lat lon
.custom-latlon-btn {
  font-size: 14px !important;
  padding: 0 10px !important;
  height: 30px;
  margin-left: 5px;
  margin-top: 1px;
}
